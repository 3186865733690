
import { Options, Vue } from "vue-class-component";
import reCAPTCHA from "@/reCAPTCHA";

@Options({})
export default class Recovery extends Vue {
	step = -3; // ask to use reCAPTCHA
	nextStep = 1; // first step after reCAPTCHA confirmation
	user = {
		email: "",
		name: "",
		totp: false,
		pwd: "",
	};

	recaptcha_key = process.env.VUE_APP_RECAPTCHA;

	async mounted () {
		// already loaded (user returned to this page)
		if (reCAPTCHA.isReady) {
			if (this.step == -3) {
				this.step = this.nextStep;
			}

			await this.$nextTick();
			reCAPTCHA.instance.render("recaptcha-container", {
				sitekey: process.env.VUE_APP_RECAPTCHA,
				size: "invisible",
			});
			reCAPTCHA.instance.reset();

			if (this.step == 1) {
				(this.$refs._email as HTMLInputElement).focus();
			}
		}
	}

	async start () {
		this.step = -4;

		try {
			await reCAPTCHA.load();
			this.step = this.nextStep;
			await this.$nextTick();

			if (this.step == 1) {
				(this.$refs._email as HTMLInputElement).focus();
			}
		} catch (err) {
			this.step = -1
		}
	}

	async checkEmail () {
		this.step = reCAPTCHA.isReady ? 2 : -1;
		// XXX: any actual checks needed here?
	}

	private sleep (milliseconds: number) {
		return new Promise(resolve => setTimeout(resolve, milliseconds));
	}

	async confirm () {
		reCAPTCHA.instance.execute();
		let token = "";
		console.log("Confirmed")

		// the recaptcha API doesn't play nice with Vue
		while (!(token = reCAPTCHA.instance.getResponse())) {
			await this.sleep(1000);
		}
		console.log("Captcha OK")

		// ${process.env.VUE_APP_API}
		// https://api.tmw2.org:13370/
		const req = new Request(`${process.env.VUE_APP_PYAPI}/tmwa_auth`, {
			method: "POST",
			mode: "no-cors",
			cache: "no-cache",
			redirect: "follow",
			referrer: "no-referrer",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				"X-CAPTCHA-TOKEN": token,
			},
			body: JSON.stringify({
				mail: this.user.email,
				user: this.user.name,
				pass: this.user.pwd,
				totp: this.user.totp,
			}),
		});
		console.log("Req OK")

		const rawResponse = await fetch(req);
		//const response: string = await rawResponse.text();
		console.log("Reply obtained")

		// FIXME: Not caught, always cause error 0
		switch (rawResponse.status) {
			// TODO: don't use alerts: embed the error message on the page
			case 0:
				this.step = 3;
				break;
			case 200:
			case 201:
				this.step = 3;
				break;
			case 400:
				self.alert("API: malformed request");
				document.location.reload();
				break;
			case 403:
				self.alert("Captcha or account validation failed.\nPlease try again later");
				document.location.reload();
				break;
			case 404:
				this.step = 1;
				reCAPTCHA.instance.reset();
				await this.$nextTick();
				(this.$refs._email as HTMLInputElement).focus();
				break;
			case 408:
				this.step = -4;
				break;
			case 429:
				self.alert("Too many requests.\nPlease try again later");
				document.location.reload();
				break;
			case 500:
				self.alert("Internal server error.\nPlease try again later");
				document.location.reload();
				break;
			case 502:
				self.alert("Couldn't reach the server.\nPlease try again later");
				document.location.reload();
				break;
			default:
				self.alert(`Unknown error: ${rawResponse.status}`);
				document.location.reload();
				break;
		}
	}

}
